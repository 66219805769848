<template>
  <div @click.stop="close">
    <button
      type="button"
      class="btn btn-primary"
      id="button-open-dialog"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      hidden
    ></button>
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header d-flex justify-content-between">
            <h2 class="modal-title">{{ $t("editproduct") }}</h2>
            <button
              data-bs-dismiss="modal"
              @click="close"
              type="button"
              class="btn btn-sm"
            >
              <i
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="font-size: 25px"
              ></i>
            </button>
          </div>

          <div class="modal-body">
            <!-- <div class="row d-flex justify-content-center mb-4">
              <label class="col-sm-9 text-start">เลขที่เอกสาร</label>
              <div class="col-sm-9">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div> -->
            <!-- <div class="row d-flex justify-content-center text-start mb-4">
              <label for="input1  form-label" class="col-sm-10">{{
                $t("findproduct")
              }}</label>
              <div class="col-sm-10">
                <input
                  v-model="searchProduct"
                  type="text"
                  class="form-control"
                  :placeholder="$t(`finditemcode`)"
                  aria-describedby="basic-addon1"
                  :disabled="isView"
                  list="datalistOptions"
                />
                <datalist
                  id="datalistOptions"
                  v-if="searchListProduct.length >= 1"
                >
                  <option
                    id="datalistOptions11"
                    v-for="(item, index) in searchListProduct"
                    :key="index"
                    :value="item.code"
                  >
                    {{ item.name }}
                    <hr style="color: LightGrey" class="mt-4 mb-0 pb-0" />
                  </option>
                </datalist>
              </div>
            </div> -->
            <div class="card-title row ms-6 me-5 mt-6">
              <div class="col-sm-3 mb-4">
                <label form-label>Code/Name</label>
                <input
                  v-model="searchInput"
                  :placeholder="$t(`search`)"
                  type="text"
                  class="form-control"
                  @keypress.enter="search()"
                />
              </div>

              <div class="col-sm-3 mt-4 text-start" style="align-self: center">
                <button
                  @click="clear()"
                  type="button"
                  class="btn btn-sm btn-light-danger me-2"
                  style="font-size: 14px; font-weight: bold"
                >
                  {{ $t("clear") }}
                </button>

                <button
                  @click="search()"
                  type="button"
                  class="btn btn-sm btn-light-primary"
                  style="font-size: 14px; font-weight: bold"
                >
                  {{ $t("search") }}
                </button>
              </div>
            </div>

            <div class="card-body" style="padding-top: 3px">
              <div class="table-responsive">
                <a-table :dataSource="dataItemsShow" :columns="columns">
                  <template #headerCell="{ column }">
                    <template v-if="column.dataIndex === 'no'">
                      {{ $t("no") }}
                    </template>
                    <template v-if="column.dataIndex === 'code'">
                      {{ $t("productcode") }}
                    </template>
                    <template v-if="column.dataIndex === 'name'">
                      {{ $t("productname") }}
                    </template>
                    <template v-if="column.dataIndex === 'productGroup'">
                      {{ $t("productgroupname") }}
                    </template>
                    <template v-if="column.dataIndex === 'cost'">
                      {{ $t("cost") }}
                    </template>
                    <template v-if="column.dataIndex === 'unit_price'">
                      {{ $t("sellprice") }}
                    </template>
                    <template v-if="column.dataIndex === 'status'">
                      {{ $t("status") }}
                    </template>
                  </template>

                  <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'no'">
                      {{ record.no }}
                    </template>
                    <template v-if="column.dataIndex === 'productGroup'">
                      <span v-if="record.productGroup">
                        {{
                          record.productGroup ? record.productGroup.code : ""
                        }}
                        {{
                          record.productGroup
                            ? " | " + record.productGroup.name
                            : ""
                        }}
                      </span>
                    </template>
                    <template v-if="column.dataIndex === 'cost'">
                      <span v-if="record.productPrices.length != 0">
                        {{ record.productPrices[0].cost }}
                      </span>
                      <span v-else>-</span>
                    </template>
                    <template v-if="column.dataIndex === 'unit_price'">
                      <span v-if="record.productPrices.length != 0">
                        {{ record.productPrices[0].unit_price }}
                      </span>
                      <span v-else>-</span>
                    </template>
                    <template v-if="column.dataIndex === 'status'">
                      <span
                        v-if="record.status == 'ขายปกติ'"
                        style="color: green"
                      ></span>
                      <span v-else style="color: red">{{ record.status }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'action'">
                      <!-- data-bs-dismiss="modal" -->
                      <button
                        class="btn btn-light btn-primary"
                        type="button"
                        @click="pushproduct(record)"
                      >
                        เลือก
                      </button>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>

            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-10">Item no</label>
              <div class="col-sm-10">
                <input
                  v-model="searchListProduct[0].code"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <div
              class="row d-flex justify-content-center text-start mb-4"
              v-if="searchListProduct[0]"
            >
              <label class="col-sm-10">Item Name</label>
              <div class="col-sm-10">
                <input
                  v-model="searchListProduct[0].name"
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div>
            <!-- <div class="row d-flex justify-content-center text-start mb-4">
              <label class="col-sm-9">Barcode</label>
              <div class="col-sm-10">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="inputGroupFile01"
                />
              </div>
            </div> -->
          </div>
          <div class="row d-flex justify-content-center text-start">
            <hr class="mt-10 mb-8" style="color: LightGrey" />
            <!-- ///หน่วย -->
            <div class="row mb-5">
              <div class="col-sm-1" />
              <div class="col-sm-11">
                <h3>
                  <i class="bi bi-cash-coin" style="font-size: 20px"></i>
                  {{ $t("unitproduct") }}
                </h3>
              </div>
            </div>

            <div class="row d-flex justify-content-center">
              <div class="col-sm-10">
                <div class="row d-flex justify-content-center">
                  <!-- <div class="col-sm-4 mb-4">
                    <label for="retailPrice" class="form-label col-sm-10">{{
                      $t("barcode")
                    }}</label>
                    <input
                      v-model="unit.barcode"
                      type="number"
                      min="0"
                      class="form-control mb-4"
                      id="retailPrice"
                      name="retailPrice"
                    />
                    <div class="invalid-feedback text-end">กรุณากรอก</div>
                  </div> -->
                  <div class="col-sm-6 mb-4">
                    <label
                      for="retailPrice"
                      class="required form-label col-sm-10"
                      id="focushere"
                    >
                      <!-- {{ $t("unitname") }} -->
                      U/M
                    </label>
                    <select
                      class="form-select mb-4"
                      v-model="unit.productUnitId"
                      id="validationTooltip01"
                      required
                    >
                      <option
                        v-for="item in units"
                        :key="item"
                        :value="item.id"
                      >
                        {{ item.code }} |
                        {{ item.name }}
                      </option>
                    </select>
                    <div class="invalid-feedback text-end">กรุณากรอก</div>
                  </div>
                  <div class="col-sm-6 mb-4">
                    <label
                      for="retailPrice"
                      class="required form-label col-sm-10"
                      >{{ $t("packagesize") }}</label
                    >
                    <input
                      v-model="unit.size"
                      class="form-control mb-4"
                      id="retailPrice"
                      name="retailPrice"
                      type="number"
                      min="0"
                      step="1"
                      required
                    />
                    <!-- @change="setdefaultsize()" -->
                    <div class="invalid-feedback text-end">กรุณากรอก</div>
                  </div>
                  <!-- <div class="row d-flex justify-content-center">
                    <div class="col-sm-4 mb-4">
                      <label
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                          me-5
                        "
                      >
                        <el-checkbox
                          v-model="unit.default"
                          type="checkbox"
                          value="true"
                        />
                        <span class="form-check-label">{{
                          $t("default")
                        }}</span>
                      </label>
                    </div>

                    <div class="col-sm-4 mb-4">
                      <label
                        class="
                          form-check
                          form-check-sm
                          form-check-custom
                          form-check-solid
                          me-5
                        "
                      >
                        <el-checkbox
                          v-model="unit.bill"
                          type="checkbox"
                          value="1"
                        />
                        <span class="form-check-label"> {{ $t("bill") }} </span>
                      </label>
                    </div>
                    <div class="col-sm-4 mb-4"></div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-sm-10">
                <label for="retailPrice" class="required form-label col-sm-10">
                  <!-- {{ $t("unitprice") }} -->
                  <span>Sale Price</span>
                </label>
                <input
                  v-model="unit_price"
                  type="number"
                  class="form-control mb-4"
                  id="retailPrice"
                  name="retailPrice"
                  required
                />
                <div class="invalid-feedback text-end">กรุณากรอก</div>
              </div>
              <!-- <div class="col-sm-5">
                <label for="retailPrice" class="required form-label col-sm-10">
                  
                  <span>Cost Price</span>
                </label>
                <input
                  v-model="unit.cost"
                  type="text"
                  min="0"
                  class="form-control"
                  id="retailPrice"
                  name="retailPrice"
                  v-mask-decimal="2"
                  required
                />
              </div> -->

              <!-- <div class="col-sm-2" style="text-align: end">
                <button
                  type="button"
                  class="btn btn-sm btn-primary mt-10"
                  style="font-size: 14px; font-weight: bold"
                  @click="appendTable()"
                >
                  {{ $t("add") }}
                </button>
              </div> -->
            </div>
            <hr class="mt-10 mb-8" style="color: LightGrey" />
          </div>
          <!-- ///หน่วย -->
          <div class="modal-footer d-flex justify-content-start">
            <hr class="pt-0 mt-0" style="color: LightGrey" />

            <footer>
              <div class="card-toolbar">
                <button
                  @click="submit"
                  type="button"
                  class="btn btn-primary me-3"
                  data-bs-dismiss="modal"
                >
                  {{ $t("save") }}
                </button>
                <button
                  @click="close"
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                >
                  {{ $t("cancel") }}
                </button>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import whApi from "@/api/warehouse/";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default {
  props: {
    dialogEditItem: Boolean,
    warehouseId: String,
  },
  data: () => ({
    isSubmit: false,
    searchProduct: "",
    searchListProduct: [],
    productstock: [],
    stockAmt: "",
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "customercode", dataIndex: "code" },
      { title: "customername", dataIndex: "name" },
      // { title: "address", dataIndex: "address" },
      // { title: "branchs", dataIndex: "branch.name" },
      // { title: "storetype", dataIndex: "business_type" },
      // { title: "route", dataIndex: "route" },
      { title: "action", dataIndex: "action" },
    ],
    dataItemsShow: [],
    units: [],
    unit: {
      // default: true,
      bill: true,
    },
    taItemsShow: [],
    searchInput: "",
  }),
  watch: {
    searchProduct(val) {
      console.log("คำค้นหา", val);
      this.addProductItems(val);
    },
    dialogEditItem(val) {
      if (val) {
        document.getElementById("button-open-dialog").click();
        // this.getProductBywarehouseId();
        this.getAllUnit();
      }
    },
    searchListProduct(val) {
      if (val.length == 1) {
        this.getProductBywarehouseId();
      }
    },
  },
  // productId {{ searchListProduct[0].id }}
  // warehouseId {{ warehouseId }}

  methods: {
    pushproduct(record) {
      console.log("record", record);
      this.searchListProduct.push(record);
      // location.href("#focushere");
      document.getElementById("focushere").focus();
    },
    clear() {
      this.searchInput = "";
    },
    async search() {
      // this.loading = true;

      // storeTypeId: this.form.storeTypeId,
      // branchId: this.form.branchId,
      // routeId: this.form.routeId,

      const body = {
        search: this.searchInput,
        companyId: localStorage.getItem("companyId"),
      };
      const responseSearch = await whApi.product.search(body);

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
    setdefaultsize() {
      // this.dataItemsShow.forEach((element) => {
      //   if (element.productUnitId == 14) {
      //     this.unit_price = element.unit_price * this.unit.size;
      //   }
      // });
      if (unit.productUnitId == 14) {
        this.unit_price = element.unit_price * this.unit.size * 1;
      }
    },
    async getAllUnit() {
      let companyId = parseInt(localStorage.getItem("companyId"));
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.unit.getAll(companyId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.units = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    changeValue(index, item) {
      this.dataItemsShow.forEach((element) => {
        element.default = false;
      });
      this.dataItemsShow[index].default = true;
    },
    appendTable() {
      this.dataItemsShow.push({
        // barcode: this.unit.barcode,
        productUnitId: this.unit.productUnitId,
        size: this.unit.size,
        default: this.unit.default,
        bill: this.unit.bill,
        unit_price: this.unit_price,
        cost: this.unit.cost,
      });
      // console.log("this.dataItemsShow", this.dataItemsShow);
    },
    goToDelete(index) {
      this.dataItemsShow.splice(index, 1);
    },
    async addProductItems(searchProduct) {
      let responseData = [];
      try {
        responseData = await whApi.product.search({ search: searchProduct });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.searchListProduct = responseData.data;
        console.log("this.searchListProduct", this.searchListProduct);
        // this.getProductBywarehouseId();
      }
    },
    async getProductBywarehouseId() {
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getProductBywarehouseId(
          this.warehouseId,
          this.searchListProduct[0].id
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.productstock = responseData.data[0];
      }
    },
    async submit() {
      let body = {
        productId: this.searchListProduct[0].id,
        code: this.searchListProduct[0].code,
        name: this.searchListProduct[0].name,
        stockAmt: this.stockAmt * 1,
        sale_price: this.unit_price * 1,
        // cost: this.unit.cost,
        spqty: this.unit.size,
        u_m: this.unit.productUnitId,
        productUnitId: this.unit.productUnitId,
        // stockAmt
        // unit.productUnitId
        // unit.size
        // unit_price
        // unit.cost
        // product_prices: this.dataItemsShow,
      };
      console.log("edittt", body);
      this.$emit("addProduct", body);
      this.$emit("closeDialogEditItem");
    },
    close() {
      this.$emit("closeDialogEditItem");
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1200px) {
  .display-phone {
    text-align: left !important;
  }
}
#close-btn:hover {
  background-color: LightGrey;
  border-radius: 25px;
  color: white;
}
</style>
